import getEvents from '@/api/events/getEvents'
import saveEvent from '@/api/events/saveEvent'

const state = { events: [], updatedEvent: {}, notifications: [] }

const actions = {
  getEventsAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getEvents
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setEventsMutation', { events: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getNotificationsAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getEvents
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setNotificationsMutation', { events: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveEventAction({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      saveEvent
        .call(this, { data })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateEventMutation', {})
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },

  clearEventsAction({ commit }) {
    commit('clearEventsMutation')
  },
}

const mutations = {
  setEventsMutation(state, { events }) {
    state.events = events.map(ev => {
      const { id, name, date_start, event, status, type } = ev
      if (event) {
        var { color, duration, all_day } = event
      }

      let output = {
        id: id,
        title: name,
        start: new Date(date_start * 1000),
        extendedProps: ev,
      }
      switch (type) {
        case 'Tasks':
          output = {
            ...output,
            end: duration
              ? new Date(date_start * 1000 + duration * 60 * 1000)
              : date_start,
            backgroundColor: color,
            color: color,
            classNames: [status == 2 ? 'closed' : ''],
            allDay: all_day,
          }
          break
        case 'holiday':
          output.title = ev.holiday_type
          output.allDay = true
          output.display = 'background'
          output.start = new Date(ev.date * 1000)
          break
        case 'birthdate':
          output.allDay = true
          break
      }

      return output
    })
  },
  setNotificationsMutation(state, { events }) {
    state.notifications = events
  },
  updateEventMutation() {},
  clearEventsMutation(state) {
    state.events = []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
